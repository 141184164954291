import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { CreateAgentLeadRequestAgentLeadTypeEnum } from '../../openapi/bff';
import BFFApiService from '../../services/BFFApiService';
import { RealApiAgentWebsiteResponse } from '../../types/real-api-types';
import { getAddressFromAddressComponent } from '../../utils/agentWebsiteUtils';
import { getThemeBgColorClassName } from '../../utils/DisplayHelpers';
import { isCASiteOnly } from '../../utils/SiteConfiguration';
import { EMAIL_REGEX, PHONE_REGEX } from '../../utils/StringUtils';
import IconButton from '../IconButton';
import { ThemeContext } from '../ThemeProvider';
import ControlledGoogleAutoCompleteInput from './controlled-inputs/ControlledGoogleAutoCompleteInput';
import ControlledTextInput from './controlled-inputs/ControlledTextInput';

interface formData {
  name: string;
  propertyAddress: google.maps.places.PlaceResult;
  email: string;
  phone: string;
}

interface AgentGenerateCMAReportProps {
  agent: RealApiAgentWebsiteResponse;
  title?: string;
  propertyAddress?: string;
}

const AgentGenerateCMAReport: React.FC<AgentGenerateCMAReportProps> = ({
  agent,
  title,
  propertyAddress,
}) => {
  const { control, handleSubmit, reset } = useForm<formData>();
  const isCASite = isCASiteOnly();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    reset({ propertyAddress: { formatted_address: propertyAddress } });
  }, [propertyAddress, reset]);

  const colorTheme = useContext(ThemeContext);

  const onSubmit = async (data: formData): Promise<void> => {
    await new BFFApiService().createAgentLead({
      agentId: agent?.agent.id as unknown as string,
      email: data.email,
      name: data.name,
      phoneNumber: data.phone,
      addressRequest: getAddressFromAddressComponent(
        data.propertyAddress.address_components
      ),
      agentLeadType: CreateAgentLeadRequestAgentLeadTypeEnum.MarketAnalysis,
      message: "I'm interested in a home evaluation report.",
    });

    setIsSubmitted(true);
  };

  return (
    <form className='bg-white w-full h-full border' title='valuation-form'>
      <div
        className={classNames('h-1.5', getThemeBgColorClassName(colorTheme))}
        style={{
          transform: 'perspective(10px) rotateX(-0.8deg)',
        }}
      />
      <div className='md:px-10 md:py-10 px-5 py-8 text-dark'>
        {!!title && (
          <p className='font-primary-medium md:text-5xl text-3xl text-center mb-8'>
            {title}
          </p>
        )}
        <div className='grid md:grid-cols-2 grid-cols-1 gap-6 mb-8'>
          <ControlledTextInput
            label='Name'
            name='name'
            variant='gray'
            placeholder='Name'
            control={control}
            rules={{ required: 'Required' }}
          />
          <ControlledGoogleAutoCompleteInput
            label='Property Address'
            name='propertyAddress'
            variant='gray'
            placeholder={
              isCASite
                ? 'Search by address or postal code'
                : 'Search by address or zip'
            }
            control={control}
            rules={{
              validate: (value) =>
                !value?.formatted_address ? 'Location is required.' : undefined,
            }}
          />
          <ControlledTextInput
            label='Email address'
            name='email'
            variant='gray'
            placeholder='Email address'
            control={control}
            rules={{
              required: 'Required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Invalid Email',
              },
            }}
          />
          <ControlledTextInput
            label='Phone number'
            name='phone'
            variant='gray'
            placeholder='Phone number'
            control={control}
            rules={{
              required: 'Required',
              validate: (value) => {
                if (value[0] === '1') {
                  return 'Exclude country code';
                } else if (!PHONE_REGEX.test(value)) {
                  return 'Invalid Phone';
                }

                return undefined;
              },
            }}
          />
        </div>
        <div className='md:w-1/2 w-full mx-auto'>
          <IconButton
            title={isSubmitted ? 'Submitted!' : 'Request Free Report'}
            buttonType='button'
            variant={colorTheme}
            onClick={handleSubmit(onSubmit)}
            rightIcon={<HiArrowNarrowRight size={24} />}
            fullWidth
            disabled={isSubmitted}
          />
        </div>
      </div>
    </form>
  );
};

export default AgentGenerateCMAReport;

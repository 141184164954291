// eslint-disable-next-line import/no-nodejs-modules
import { ParsedUrlQuery } from 'querystring';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import AgentHomeRequestReportCard from '../../components/agent/AgentGenerateCMAReport';
import AgentLayoutRedesigned from '../../components/agent/AgentLayoutRedesigned';
import Seo from '../../components/Seo';
import ThemeProvider from '../../components/ThemeProvider';
import ContentfulService from '../../services/ContentfulService';
import { CommonPageDataProps } from '../../types';
import { RealApiAgentWebsiteResponse } from '../../types/real-api-types';
import { getAgentWebsiteApiResponse } from '../../utils/agentWebsiteUtils';
import { OfficesResponse } from '../../openapi/bff';
import BFFApiService from '../../services/BFFApiService';
import ErrorService from '../../services/ErrorService';

interface AgentSellPageProps extends CommonPageDataProps {
  agent: RealApiAgentWebsiteResponse;
  allOffices: OfficesResponse;
}

interface PageParams extends ParsedUrlQuery {
  agentSlug: string;
}

const AgentSellPage: NextPage<AgentSellPageProps> = ({
  agent,
  countriesWithStates,
  footerContent,
  allOffices,
}) => {
  const router = useRouter();
  const { search_label } = router.query;

  const requestCardTitle = search_label
    ? 'What did other homes in this area sell for?'
    : 'How much is my home worth?';

  return (
    <ThemeProvider theme={agent.website.color_theme}>
      <AgentLayoutRedesigned
        agentWebsiteResponse={agent}
        countriesWithStates={countriesWithStates}
        variant='primary'
        showFooterCTA
        footerContent={footerContent}
        allOffices={allOffices}
      >
        <Seo
          title={`${agent.agent.name} - Comparitive Market Analysis`}
          description='Request a comparitive market analysis for any property'
          imageUrl='/img/default-socialimage.png'
        />
        <div className='relative w-full h-screen bg-gray-50 flex flex-row row flex-nowrap items-center justify-center overflow-hidden'>
          <Image
            alt='CMA Splash'
            src='/img/cma-splash.jpeg'
            layout='fill'
            objectFit='cover'
            className='blur-sm'
          />
          <div className='relative flex items-center justify-center lg:max-w-3xl w-full m-3'>
            <AgentHomeRequestReportCard
              agent={agent}
              title={requestCardTitle}
              propertyAddress={search_label as string}
            />
          </div>
        </div>
      </AgentLayoutRedesigned>
    </ThemeProvider>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps<
  AgentSellPageProps,
  PageParams
> = async (ctx) => {
  const contentfulService = new ContentfulService();
  const bffApiService = new BFFApiService();

  const [agentWebsiteResponse, countriesWithStates, footerContent, allOffices] =
    await Promise.all([
      getAgentWebsiteApiResponse(ctx.params.agentSlug),
      contentfulService.getCountriesWithStates(),
      contentfulService.getFooterContent(),
      bffApiService.fetchAllOfficialOffices(),
    ]);

  if (!agentWebsiteResponse) {
    return { notFound: true, revalidate: 1 };
  }

  if (!countriesWithStates) {
    const error = new Error('Unable to load agent website cma page');
    ErrorService.notify('Unable to load agent website cma page!', error, {
      apiCalls: {
        countriesWithStates: !!countriesWithStates,
      },
    });

    throw error;
  }

  return {
    props: {
      agent: agentWebsiteResponse,
      countriesWithStates,
      footerContent,
      allOffices,
    },
  };
};

export default AgentSellPage;

import { Autocomplete } from '@react-google-maps/api';
import classNames from 'classnames';
import React, { HTMLInputTypeAttribute, useCallback, useState } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { AiOutlineSearch } from 'react-icons/ai';
import { GOOGLE_MAPS_REQUESTED_FIELDS } from '../../../constants/GoogleMapsConstants';
import { useGoogleMapsAPILoader } from '../../../hooks/useGoogleMapsAPILoader';
import ServerLogger from '../../../utils/ServerLogger';
import { getRestrictedCountry } from '../../../utils/SiteConfiguration';
import ErrorMessage from '../../ErrorMessage';

export type ControlledGoogleAutoCompleteInputVariant = 'white' | 'gray';

export interface ControlledGoogleAutoCompleteInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  type?: HTMLInputTypeAttribute | undefined;
  label?: string;
  placeholder?: string;
  variant: ControlledGoogleAutoCompleteInputVariant;
}

const ControlledGoogleAutoCompleteInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  type = 'text',
  placeholder,
  variant,
  ...rest
}: ControlledGoogleAutoCompleteInputProps<
  TFieldValues,
  TName
>): React.ReactElement => {
  const {
    field,
    fieldState: { error },
  } = useController(rest);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>(undefined);
  const restrictedCountry = getRestrictedCountry();

  const isLoaded = useGoogleMapsAPILoader();

  const onLoad = useCallback(
    (autocomplete) => {
      setAutocomplete(autocomplete);
    },
    [setAutocomplete]
  );

  const onPlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();

      field.onChange(place);
    } else {
      ServerLogger.error(
        'Autocomplete is null, cannot send place onPlaceChanged.'
      );
    }
  }, [autocomplete, field.onChange]);

  const variantMapClassName: Record<
    ControlledGoogleAutoCompleteInputVariant,
    string
  > = {
    white: 'bg-white',
    gray: 'bg-gray-100',
  };

  return (
    <div className='space-y-1 w-full'>
      {(label || error) && (
        <div className='flex flex-row items-center space-x-3'>
          {label && (
            <label className='inline-block' htmlFor={field.name}>
              {label}
            </label>
          )}
          <ErrorMessage message={error?.message} />
        </div>
      )}
      <div
        className={classNames(
          'flex items-center rounded-sm overflow-hidden space-x-1 px-2 text-gray-500',
          variantMapClassName[variant],
          !error ? 'border-gray-50' : 'border-red-600'
        )}
      >
        <AiOutlineSearch size={20} />
        {isLoaded && (
          <Autocomplete
            className='w-full'
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            restrictions={{
              country: restrictedCountry,
            }}
            options={{
              fields: GOOGLE_MAPS_REQUESTED_FIELDS,
            }}
          >
            <input
              id={field.name}
              {...field}
              value={field.value?.formatted_address}
              type={type}
              inputMode='search'
              placeholder={placeholder}
              className='grow appearance-none px-1 py-2.5 border-0 font-primary-light text-gray-500 text-sm leading-none bg-white focus:outline-none focus:ring-0 w-full bg-transparent'
            />
          </Autocomplete>
        )}
      </div>
    </div>
  );
};

export default ControlledGoogleAutoCompleteInput;
